import { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router";
import { store, persistor, PersistGate } from "@syntbeheer/api/store";
import { Provider } from "react-redux";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import { getDefinitions } from "@syntbeheer/api/services/definitions/index";
import { Font } from "@react-pdf/renderer";
import ReactGA from "react-ga";
import Poppins from "./Poppins.ttf";
import PoppinsBold from "./Poppins-Bold.ttf";
import PoppinsSemiBold from "./Poppins-SemiBold.ttf";
import PoppinsMedium from "./Poppins-Medium.ttf";
import "./components/pages/dashboard/dashboard.css";

import device from "@syntbeheer/api/services/users/device";
import ErrorBoundary from "./components/pages/ErrorBoundary.js";

const OnePager = lazy(() => import("./components/pages/v2/OnePager.js"));
const Main = lazy(() => import("./components/pages/Main.js"));
const Login = lazy(() => import("./components/pages/Login.js"));
const Register = lazy(() => import("./components/pages/Register.js"));
const Privacy = lazy(() => import("./components/pages/Privacy.js"));
const Terms = lazy(() => import("./components/pages/Terms.js"));
const PublicAgenda = lazy(() => import("./components/pages/stream/Public.js"));
const PublicIncident = lazy(() =>
  import("./components/pages/incident/Public.js")
);
const MobileBanking = lazy(() =>
  import("./components/pages/dashboard/accounting/MobileBanking.js")
);
const MobilePayment = lazy(() =>
  import("./components/pages/dashboard/accounting/MobilePayment.js")
);
const Dashboard = lazy(() =>
  import("./components/pages/dashboard/Dashboard.js")
);
const Association = lazy(() =>
  import("./components/pages/dashboard/post/Association.js")
);
const Reporttypes = lazy(() =>
  import("./components/pages/dashboard/post/definitions/Reporttypes.js")
);
const Documenttypes = lazy(() =>
  import("./components/pages/dashboard/post/definitions/Documenttypes.js")
);
const Journal = lazy(() =>
  import("./components/pages/dashboard/post/definitions/Journal.js")
);
const PaymentCondition = lazy(() =>
  import("./components/pages/dashboard/post/definitions/PaymentCondition.js")
);
const GeneralLedgerDefinition = lazy(() =>
  import(
    "./components/pages/dashboard/post/definitions/GeneralLedgerDefinition.js"
  )
);
const FinancialYear = lazy(() =>
  import("./components/pages/dashboard/accounting/FinancialYear.js")
);
const Settlement = lazy(() =>
  import("./components/pages/dashboard/accounting/Settlement/Settlement.js")
);
const FixedMeetingItem = lazy(() =>
  import("./components/pages/dashboard/post/definitions/FixedMeetingItem.js")
);
const Incident = lazy(() =>
  import("./components/pages/dashboard/post/Incident.js")
);
const Document = lazy(() =>
  import("./components/pages/dashboard/post/Document.js")
);
const Meeting = lazy(() =>
  import("./components/pages/dashboard/post/Meeting.js")
);
const Reminder = lazy(() =>
  import("./components/pages/dashboard/post/Reminder.js")
);
const Supplier = lazy(() =>
  import("./components/pages/dashboard/post/Supplier.js")
);
const Lot = lazy(() => import("./components/pages/dashboard/post/Lot.js"));
const DistributionKey = lazy(() =>
  import("./components/pages/dashboard/post/DistributionKey.js")
);
const User = lazy(() => import("./components/pages/dashboard/post/User.js"));
const StreamMeeting = lazy(() =>
  import("./components/pages/dashboard/stream/Meeting.js")
);
const Purchase = lazy(() =>
  import("./components/pages/dashboard/accounting/Purchase.js")
);
const Provision = lazy(() =>
  import("./components/pages/dashboard/accounting/Provision.js")
);
const GeneralLedgerAccounts = lazy(() =>
  import("./components/pages/dashboard/accounting/GeneralLedgerAccounts.js")
);
const Banking = lazy(() =>
  import("./components/pages/dashboard/accounting/Banking.js")
);
const Payment = lazy(() =>
  import("./components/pages/dashboard/accounting/Payment.js")
);
const Error = lazy(() => import("./components/pages/Error.js"));

Font.register({
  family: "Poppins",
  fonts: [
    { src: Poppins },
    { src: PoppinsBold, fontWeight: 900 },
    { src: PoppinsSemiBold, fontWeight: 700 },
    { src: PoppinsMedium, fontWeight: 500 },
  ],
});

Font.registerEmojiSource({
  format: "png",
  url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
});

ReactGA.initialize("UA-215638009-1");

const loadGMaps = (callback) => {
  const existingScript = document.getElementById("googleMaps");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCims7Sdhido85LYt0Lq_t793nSsFrhoIY&libraries=places";
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const updateGA = () => {
  console.log(
    "sending page view:",
    window.location.pathname + window.location.search
  );
  ReactGA.pageview(window.location.pathname + window.location.search);
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ element: Element, ...rest }) {
  const auth = usersSelectors.get();
  const location = useLocation();

  console.log("user: " + JSON.stringify(auth));

  if (!auth.User.id) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Suspense fallback={<div></div>}>
      <Element {...rest} />
    </Suspense>
  );
}

function PublicRoute({ element: Element, ...rest }) {
  return (
    <Suspense fallback={<div></div>}>
      <Element {...rest} />
    </Suspense>
  );
}

const redirectToMobileApp = () => {
  const path = window.location.pathname;
  const excludedPathSegments = ["banking", "payment"];
  const shouldExclude =
    !path.includes("app") ||
    excludedPathSegments.some((segment) => path.includes(segment));

  if (shouldExclude) {
    return; // Exit function if the path should not be redirected
  }

  device.then((dev) => {
    console.log({ dev });
    if (dev.type === "web" && dev.info.isMobile) {
      console.log("try redirect");
      // open app url
      const android_url =
        "https://play.google.com/store/apps/details?id=com.synt.app";
      const ios_url =
        "https://apps.apple.com/us/app/synt-beheer-zelf-je-gebouw/id1569567329";
      window.location.href = dev.device_name.includes("Apple")
        ? ios_url
        : android_url;
    }
  });
};

function App() {
  useEffect(() => {
    updateGA();
    // update app in background
    // TODO: Add some memory variable to update defs only once in a session
    getDefinitions(); // stores definitions into redux
    loadGMaps();
    redirectToMobileApp();
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <Router>
            <Routes>
              <Route path="/" element={<PublicRoute element={OnePager} />} />
              <Route
                path="/old-one-pager"
                element={<PublicRoute element={Main} />}
              />
              <Route path="/login" element={<PublicRoute element={Login} />} />
              <Route
                path="/register"
                element={<PublicRoute element={Register} />}
              />
              <Route
                path="/privacy-policy"
                element={<PublicRoute element={Privacy} />}
              />
              <Route
                path="/terms-and-conditions"
                element={<PublicRoute element={Terms} />}
              />
              <Route
                path="/agenda/:token?"
                element={<PublicRoute element={PublicAgenda} />}
              />
              <Route
                path="/incident/:token?"
                element={<PublicRoute element={PublicIncident} />}
              />
              <Route
                path="/app/banking/mobile"
                element={<PublicRoute element={MobileBanking} />}
              />
              <Route
                path="/app/payment/mobile"
                element={<PublicRoute element={MobilePayment} />}
              />
              <Route
                path="/app"
                element={<PrivateRoute element={Dashboard} />}
              />
              <Route
                path="/app/association/:id?"
                element={<PrivateRoute element={Association} />}
              />
              <Route
                path="/app/reporttypes/:id?"
                element={<PrivateRoute element={Reporttypes} />}
              />
              <Route
                path="/app/documenttypes/:id?"
                element={<PrivateRoute element={Documenttypes} />}
              />
              <Route
                path="/app/journal/:id?"
                element={<PrivateRoute element={Journal} />}
              />
              <Route
                path="/app/payment-condition/:id?"
                element={<PrivateRoute element={PaymentCondition} />}
              />
              <Route
                path="/app/general-ledger-definition/:id?"
                element={<PrivateRoute element={GeneralLedgerDefinition} />}
              />
              <Route
                path="/app/financial-year/:id?"
                element={<PrivateRoute element={FinancialYear} />}
              />
              <Route
                path="/app/settlement/financial-year/:id?"
                element={<PrivateRoute element={Settlement} />}
              />
              <Route
                path="/app/fixedmeetingitem/:id?"
                element={<PrivateRoute element={FixedMeetingItem} />}
              />
              <Route
                path="/app/incident/:id?"
                element={<PrivateRoute element={Incident} />}
              />
              <Route
                path="/app/document/:id?"
                element={<PrivateRoute element={Document} />}
              />
              <Route
                path="/app/agenda/:id?"
                element={<PrivateRoute element={Meeting} />}
              />
              <Route
                path="/app/reminders/default/:id?"
                element={<PrivateRoute element={Reminder} />}
              />
              <Route
                path="/app/reminders/:id?"
                element={<PrivateRoute element={Reminder} />}
              />
              <Route
                path="/app/supplier/:id?"
                element={<PrivateRoute element={Supplier} />}
              />
              <Route
                path="/app/lot/:id?"
                element={<PrivateRoute element={Lot} />}
              />
              <Route
                path="/app/distribution-key/:id?"
                element={<PrivateRoute element={DistributionKey} />}
              />
              <Route
                path="/app/user/:id?"
                element={<PrivateRoute element={User} />}
              />
              <Route
                path="/app/stream/agenda/:id"
                element={<PrivateRoute element={StreamMeeting} />}
              />
              <Route
                path="/app/invoice/:id?"
                element={<PrivateRoute element={Purchase} />}
              />
              <Route
                path="/app/provision/:id?"
                element={<PrivateRoute element={Provision} />}
              />
              <Route
                path="/app/general-ledger-accounts"
                element={<PrivateRoute element={GeneralLedgerAccounts} />}
              />
              <Route
                path="/app/banking"
                element={<PrivateRoute element={Banking} />}
              />
              <Route
                path="/app/payment"
                element={<PrivateRoute element={Payment} />}
              />
              <Route path="*" element={<PublicRoute element={Error} />} />
            </Routes>
          </Router>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
}

export default App;
