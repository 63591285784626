import React from "react";
import { postRequest } from "@syntbeheer/api/services/requests";
import logo_red from "@syntbeheer/assets/brand/logo_red.svg";

const PrimaryButton = ({ title, onClick, className }) => {
  return (
    <button
      className={`bg-[#e40f18] text-white md:px-10 px-3 py-2 rounded mx-3 ${className}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

const SecondaryButton = ({ title, onClick }) => {
  return (
    <button
      className="bg-[#5267d9] text-white px-4 py-2 rounded mx-3"
      onClick={onClick}
    >
      {title}
    </button>
  );
};
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });

    postRequest({
      message: error.toString() + "//" + errorInfo.componentStack,
      category: "error",
    }).then((response) => {
      console.log(response);
      if (response.success) {
        this.setState({ success: true });
      }
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
          <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
            <img
              src={logo_red}
              alt="Synt Logo"
              className="w-16 h-16 mx-auto mb-6"
            />

            <h1 className="text-2xl font-bold text-gray-800 mb-4">
              Oeps! Er is iets misgegaan
            </h1>

            <p className="text-gray-600 mb-6">
              Geen zorgen! Ons team is automatisch op de hoogte gebracht en
              werkt aan een oplossing. Probeer één van de volgende opties:
            </p>

            <div className="space-y-4">
              <PrimaryButton
                title="Vernieuw de pagina"
                onClick={() => window.location.reload()}
              />

              <SecondaryButton
                title="Ga naar de homepage"
                onClick={() => (window.location.href = "/")}
              />

              <SecondaryButton
                title="Mail Ons"
                onClick={() =>
                  (window.location.href = `mailto:mail@synt.be?subject=Error Report&body=${encodeURIComponent(
                    `Hi Felie,

An error occurred while using the SYNT platform:

                  ${this.state.error}

Browser: ${navigator.userAgent}
URL: ${window.location.href}
Timestamp: ${new Date().toISOString()}
`
                  )}`)
                }
              />
            </div>

            {process.env.NODE_ENV === "development" && (
              <div className="mt-6 text-left text-sm text-gray-500 bg-gray-50 p-4 rounded">
                <p className="font-semibold mb-2">Error details:</p>
                <p>{this.state.error?.toString()}</p>
                <pre className="mt-2 overflow-auto">
                  {this.state.errorInfo?.componentStack}
                </pre>
              </div>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
